/**
 * Direttiva che crea un carousel basato su Slick carousel
 * Il $timeout Ã¨ necessario affinchÃ¨ la direttiva costruisca il componente
 */

angular.module('app').directive("dayCard", ['$timeout', '$translate', ($timeout: any, $translate: any) => {
    return {
        restrict: 'AE',
        replace: 'true',
        templateUrl: 'app/shared/dayCard/dayCard.html',
        scope: {
            data: "=",
            selectedDays: '=',
            updateCourseDate: '='
        },

        link: (scope: any, element: any, attrs: any) => {
            let initializeCard: Function;
            let isInitialized: boolean;
            scope.selectedDay = null;
            scope.clonedStartTime = null;
            scope.clonedEndTime = null;

            scope.clearText = $translate.instant("editCourse.CLEAR");
            scope.todayText = $translate.instant("editCourse.TODAY");
            scope.nowText = $translate.instant("editCourse.NOW");
            scope.dateText = $translate.instant("editCourse.DATE");
            scope.timeText = $translate.instant("editCourse.TIME");
            scope.closeText = $translate.instant("editCourse.CLOSE");

            scope.timeOptions = {
                readonlyInput: false,
                showMeridian: false
            }

            scope.startTimePopup = {
                opened: false
            };

            scope.openStartTime = () => {
                scope.startTimePopup.opened = true
            }

            scope.endTimePopup = {
                opened: false
            };

            scope.openEndTime = () => {
                scope.endTimePopup.opened = true
            }

            // Inizializzazione
            initializeCard = () => {
                return $timeout(() => {
                    let currentIndex: number;
                    let customPaging: any;
                    let card: any = null;
                    let ulElement: any = null;
                    let arrow: any = null;
                    let editionDdayEexpandedContainer: any = null;
                    let editionDdayEexpandedContainertop: any = null;
                    let cardnum: any = null;
                    let cardinfo: any = null;
                    let cardhandle: any = null;
                    let editionDayList: any = null;
                    let clickedLi: any = null;

                    scope.getElement = (event: any) => {
                        return angular.element(event.srcElement || event.target);
                    }

                    // Deseleziona il giorno
                    scope.deselectDay = (day: any) => {
                        if (day && scope.selectedDays && scope.selectedDays.length) {
                            for (let i = 0, dataLength = scope.selectedDays.length; i < dataLength; i++) {
                                let currentData = scope.selectedDays[i];
                                if (currentData.courseDateDay === day.courseDateDay) {
                                    scope.selectedDays.splice(i, 1);
                                    day.dayIncluded = false;
                                    break;
                                }
                            }
                            for (let i = 0, dataLength = scope.data.length; i < dataLength; i++) {
                                let currentData = scope.data[i];
                                if (currentData.courseDateDay === day.courseDateDay) {
                                    currentData.dayIncluded = false;
                                    currentData.startTime = null;
                                    currentData.endTime = null;
                                    break;
                                }
                            }
                        }
                        // Aggiorno il corso
                        scope.updateCourseDate(false, false);
                    };

                    scope.moveCard = (el: any, day: any) => {
                        if (clickedLi) return;

                        card = $(element);
                        arrow = card.find("svg");
                        editionDdayEexpandedContainer = card.find('.edition-day-expanded-container');
                        editionDdayEexpandedContainer.css('display', 'flex');
                        editionDdayEexpandedContainertop = editionDdayEexpandedContainer.find('.edition-day-expanded-container-top');
                        cardnum = editionDdayEexpandedContainertop.find('.edition-day-expanded-container-num');
                        cardinfo = editionDdayEexpandedContainer.find('.edition-day-expanded-container-info');
                        cardhandle = editionDdayEexpandedContainer.find('.edition-day-expanded-container-handle');
                        editionDayList = card.find('.edition-day-list');
                        ulElement = card.find('ul');
                        clickedLi = el;
                        let color = $(el.currentTarget).css('border-top-color');
                        editionDdayEexpandedContainertop.css('background-color', color);
                        cardhandle.css('color', color);

                        let cilckedElement = scope.getElement(el);
                        let selfO = ulElement.offset().top - el.pageY;
                        let ty = ulElement[0].clientHeight / 2 - (-selfO) - 4;
                        scope.selectedDay = day;
                        scope.clonedStartTime = angular.copy(day.startTime);
                        scope.clonedEndTime = angular.copy(day.endTime);

                        $(el.currentTarget).css({
                            'transform': 'translateY(' + ty + 'px)'
                        });

                        $(el.currentTarget).on('transitionend', () => {
                            angular.element(element)[0].scrollTop = 0;
                            element.css({
                                'overflow-y': 'hidden'
                            });

                            editionDdayEexpandedContainer.addClass('active');
                            editionDayList.addClass('card-list-opacity-layer');
                            $(el.currentTarget).off('transitionend');
                        });
                    };

                    scope.closeCard = (el: any) => {
                        card = $(element);
                        arrow = card.find("svg");
                        editionDdayEexpandedContainer = card.find('.edition-day-expanded-container');
                        editionDdayEexpandedContainertop = editionDdayEexpandedContainer.find('.edition-day-expanded-container-top');
                        cardnum = editionDdayEexpandedContainertop.find('.edition-day-expanded-container-num');
                        cardinfo = editionDdayEexpandedContainer.find('.edition-day-expanded-container-info');
                        cardhandle = editionDdayEexpandedContainer.find('.edition-day-expanded-container-handle');
                        editionDayList = card.find('.edition-day-list');

                        scope.selectedDay = null;
                        scope.clonedStartTime = null;
                        scope.clonedEndTime = null;

                        editionDdayEexpandedContainer.removeClass('active');
                        cardnum.hide();
                        cardinfo.hide();
                        cardhandle.hide();
                        editionDdayEexpandedContainer.on('transitionend', () => {
                            editionDdayEexpandedContainer.css('display', 'none');
                            editionDayList.removeClass('card-list-opacity-layer');
                            element.css({
                                'overflow-y': 'scroll'
                            });
                            card.removeAttr('style');
                            $(clickedLi.currentTarget).removeAttr('style');
                            clickedLi = null;
                            cardnum.show();
                            cardinfo.show();
                            cardhandle.show();
                            editionDdayEexpandedContainer.off('transitionend');
                        });
                    };

                    scope.saveCard = (el: any, selectedDay: any) => {
                        // Salvo la selezione. Se è già selezionata, devo solo aggiornare le date
                        let alreadyAdded = false;
                        if (scope.selectedDays && scope.selectedDays.length) {
                            for (let i = 0, dataLength = scope.selectedDays.length; i < dataLength; i++) {
                                let currentData = scope.selectedDays[i];
                                //if (currentData.courseDateDay === scope.selectedDay.courseDateDay) {
                                if (currentData.courseDateDay === selectedDay.courseDateDay) {
                                    alreadyAdded = true;
                                    // currentData.startTime = angular.copy(scope.clonedStartTime);
                                    // currentData.endTime = angular.copy(scope.clonedEndTime);
                                    currentData.startTime = angular.copy(selectedDay.startTime);
                                    currentData.endTime = angular.copy(selectedDay.endTime);
                                    break;
                                }
                            }
                            /*
                            for (let i = 0, dataLength = scope.data.length; i < dataLength; i++) {
                                let currentData = scope.data[i];
                                if (currentData.courseDateDay === scope.selectedDay.courseDateDay) {
                                    currentData.startTime = angular.copy(scope.clonedStartTime);
                                    currentData.endTime = angular.copy(scope.clonedEndTime);
                                    break;
                                }
                            }*/
                        }

                        if (!alreadyAdded && scope.selectedDays) {
                            selectedDay.dayIncluded = true;
                            //scope.selectedDay.dayIncluded = true;
                            // scope.selectedDay.startTime = angular.copy(scope.clonedStartTime);
                            // scope.selectedDay.endTime = angular.copy(scope.clonedEndTime);
                            // scope.selectedDays.push(angular.copy(scope.selectedDay));
                            scope.selectedDays.push(selectedDay);
                            // Ora seleziono graficamente la giornata, e salvo gli orari
                            for (let i = 0, dataLength = scope.data.length; i < dataLength; i++) {
                                let currentData = scope.data[i];
                                // if (currentData.courseDateDay === scope.selectedDay.courseDateDay) {
                                if (currentData.courseDateDay === selectedDay.courseDateDay) {
                                    currentData.dayIncluded = true;
                                    // currentData.startTime = angular.copy(scope.clonedStartTime);
                                    // currentData.endTime = angular.copy(scope.clonedEndTime);
                                    break;
                                }
                            }
                        }

                        // scope.closeCard(el);

                        // Adesso che ho aggiunto la data selezionata, aggiorno il corso
                        scope.updateCourseDate(false, false);
                    }
                });
            };
            return initializeCard();
        }
    }
}]);